import * as moment from 'moment/moment';

Date.prototype.toISOString = function () {  return moment(this).utcOffset(0,true).toJSON(); }
declare module 'moment' {
    export interface Moment {        
        toJSON(): string;
    }
  }


  (moment.fn as any).toJSON = function () {

    const _self = this as moment.Moment;
    return moment(_self).utcOffset(0,true).format();
  };