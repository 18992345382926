import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localePT from '@angular/common/locales/pt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingScreenComponent } from './shared/loading-screen/loading-screen.component';
import { ErrorComponent } from './error/error.component';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { ConfigModule, ConfigService, ConfigLoader } from "@ngx-config/core";
import { ConfigHttpLoader } from "@ngx-config/http-loader";
import { registerLocaleData, CommonModule } from '@angular/common';
import { TranslateModule } from './translate/translate.module';
import { FormsModule } from '@angular/forms';
import { TRANSLATION_PROVIDERS, TranslateService } from './translate';
import { BaseService } from './services/base.service';
import { AutenticacaoService } from './services/autenticacao.service';
import { AuthenticationGuard } from './shared/authentication.guard';
import { LoadingScreenInterceptor } from './services/loading.interceptor';
import { AutenticacaoInterceptor } from './services/autenticacao.interceptor';
import { ErroService } from './services/erro.service';
import { PlataformaService } from './services/plataforma.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { SizeDetectorComponent } from './shared/sizedetector/size-detector.component';
import { ResizeService } from './services/resize.service';
import { InputModule } from './shared/input/input.module';



export function configFactory(http: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(http, "./config.json"); // API ENDPOINT
}

registerLocaleData(localePT);
@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    ErrorComponent,    
    SizeDetectorComponent,
  ],
  imports: [    
    CommonModule,
    BrowserModule,   
    HttpClientModule,
        FormsModule,
        ConfigModule,
        TranslateModule.forRoot(),
        ConfigModule.forRoot({
            provide: ConfigLoader,
            useFactory: configFactory,
            deps: [HttpClient]
        }),
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  entryComponents:[],
  providers: [TRANSLATION_PROVIDERS,
    TranslateService,
    BaseService,
    AutenticacaoService,
    AuthenticationGuard,
    PlataformaService,
    ErroService,
    ResizeService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingScreenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AutenticacaoInterceptor,
        multi: true
    }, 
    { provide: LOCALE_ID, useValue: 'pt-br' },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000}}],
  bootstrap: [AppComponent]
})
export class AppModule { }
