export const LANG_PT_NAME = 'pt-br';

export const LANG_PT_TRANS = {
    "SeparadorMilhar": ".",
    "SeparadorDecimal": ",",
    "FormatoDataCampo": "dd/mm/yy",
    "ErroAplicacao": "Houve um erro na aplicação",
    "ErroAplicacao_Mensagem": "Houve um erro ao processar sua requisição Por favor tente novamente e se o problema persistir, contate um administrador.",
    "ErroAplicacao_Descricao": "Descrição do erro",
    "Pesquisa": "Filtros",
    "Selecione": "Selecione",
    "Pesquisar": "Pesquisar",
    "Sim": "Sim",
    "Nao": "Não",
    "Acao": "Ação",
    "Novo": "Novo",
    "Salvar": "Salvar",
    "ValidarErro": "Alertas",
    "Cancelar": "Cancelar",
    "Manutencao": "Manutenção",
    "Voltar": "Voltar",
    "Paginacao_a": "a",
    "Paginacao_de": "de",
    "Paginacao_registros": "registros",
    "Paginacao_registrospagina": "Mostrar linhas",
    "MensagemReservado": "Due Diligence Imobiliária é um sistema da <br />E-Fluxus. Todos direitos reservados.",
    "TituloAplicacao": "Due Diligence Imobiliária",
    "BemVindo": "Olá, seja bem vindo!",
    "AcessoConta": "Acesse a sua conta com os dados abaixo.",
    "EMail": "E-mail",
    "Senha": "Senha",
    "EsquecerSenha": "Esqueceu a senha?",
    "Acessar": "Acessar",
    "ObterCodigo": "Obter Código",
    "PrecisamosVerificarIdentidade": "Precisamos verificar sua identidade",
    "CodigoEmail": "Você receberá um código em seu e-mail",
    "VerificarIdentidade": "Verificar sua identidade",
    "InsiraCodigo": "Insira o código recebido no e-mail ",
    "InserirCodigo": "Inserir código",
    "Proximo": "Próximo",
    "RedefinirSenha": "Redefinir senha",
    "RegraSenha": "Mínimo de 8 caracteres, diferencia maiúsculas e minúsculas.",
    "NovaSenha": "Nova senha",
    "RedigitarSenha": "Redigitar senha",
    "SelecioneEmpresa": "Selecione a empresa",
    "Empresa": "Empresa",
    "Confirmar": "Confirmar",
    "Inicio": "Início",
    "Operacoes": "Operações",
    "Clientes": "Clientes",
    "Gestao": "Gestão",
    "DadosMatricula": "Dados Matrícula",
    "Grupo": "Grupo",
    "Officer": "Officer",
    "Analista": "Analista",
    "AreaSegmento": "Área Segmento",
    "Diretorio": "Diretoria",
    "ControleEmpresa": "Controle de empresas",
    "ControleUsuario": "Controle de usuários",
    "ControlePerfil": "Controle de perfil",
    "Nome": "Nome",
    "Codigo": "Código",
    "Descricao": "Descrição",
    "NomeSistema": "DueDiligence",
    "ConfirmarExclusaoGrupo": "Confirma a exclusão do grupo {0}?",
    "ConfirmarExclusaoDiretoria": "Confirma a exclusão da diretoria {0}?",
    "ConfirmarExclusaoAreaSegmento": "Confirma a exclusão da área segmento {0}?",
    "ConfirmarExclusaoUsuario": "Confirma a exclusão do usuário {0}?",
    "ConfirmarExclusaoPerfil": "Confirma a exclusão do perfil {0}?",
    "ConfirmarExclusaoEmpresa": "Confirma a exclusão da empresa {0}?",
    "ConfirmarExclusaoFilial": "Confirma a exclusão da filial {0}?",
    "ConfirmarExclusaoCliente": "Confirma a exclusão do cliente {0}?",
    "AlertaCCIR": "Na hipótese do imóvel ter mais de um proprietário, favor proceder a busca das certidões dos demais proprietários inserindo o(s) número(s) do(s) CPF(s) e/ou CNPJ(s) correspondente(s) na opção \"Pesquisar Certidões\".",
    "Filiais": "Filiais",
    "Certidoes": "Certidões",
    "PossuiFilial": "Possui Filial",
    "ConfirmarSenha": "Confirmação Senha",
    "ConfirmarExclusaoOperacao": "Confirma Exclusão da operação {0}?",
    "LogAcesso": "Log de Acesso",
    "LogAlteracao": "Log de Alterações",
    "InformacoesGerais": "Informações Gerais",
    "Matriculas": "Matrículas",
    "ClienteNaoEncontradoDocumento": "Não existe cliente com o documento informado",
    "AnalisarMatricula": "Analisar Matrícula",
    "TipoImovel": "Tipo do Imóvel",
    "Proprietario": "Proprietário",
    "NomeProprietario": "Nome Proprietário",
    "ImpactoLiquidez": "Impacto na Liquidez",
    "EnviarArquivoMatricula": "Selecione os arquivos de matrícula em formato pdf a serem analisados.",
    "AddAqquivoComputador": "Adicionar um arquivo do seu computador",
    "ArrasteArquivo": "Clique ou arraste e solte seu arquivo",
    "SelecionarArquivoEnvio": "Deve ser selecionado um arquivo para envio",
    "VerDetalhes": "Ver Detalhes",
    "DetalhesMatricula": "Detalhes da matrícula",
    "MatriculaRegistroImovel": "Matrícula de Registro de Imóvel",
    "AtualizarMatricula": "Atualizar Matrícula",
    "NumeroMatricula": "Número da Matrícula",
    "CodigoIncra": "Código do Imóvel(INCRA)",
    "NomeFazenda": "Nome da Fazenda",
    "NIRF": "NIRF",
    "NIRFs": "Código(s) NIRF",
    "AreaHa": "Área (ha)",
    "NumeroContribuinte": "Número do Contribuinte",
    "NaoIdentificado": "Não Identificado",
    "NaoAplica": "Não se Aplica",
    "Proprietarios": "Proprietários",
    "AverbacoesRegistros": "Averbações e Registros",
    "NumAto": "° Ato",
    "Abertura": "Abertura",
    "Valido": "Válido",
    "Invalido": "Inválido",
    "DeixeComentarios": "Deixe seus comentários aqui",
    "CalculadoraHipoteca": "Calculadora de Hipotecas",
    "HistoricoHipotecas": "Histórico de Hipotecas",
    "Ato": "Ato",
    "Data": "Data",
    "Evento": "Evento",
    "CredorEmitente": "Credor/Emitente",
    "Valor": "Valor",
    "ValorTotalGarantido": "Valor total histórico garantido por Hipotecas",
    "QuantidadeHipoteca": "Quantidade de Hipotecas existentes",
    "Area": "Área",
    "Parecer": "Parecer",
    "Estatisticas": "Estatísticas",
    "HistoricoArea": "Histórico de área",
    "QuantidadePaginas": "Quantidade de Páginas",
    "TempoOCR": "Tempo de OCR",
    "NumeroDocumentos": "Quantidade de Documentos",
    "TempoAnalise": "Tempo da IA",
    "TempoTotalProcessamento": "Tempo Total de Processamento",
    "NivelConfiancaOCR": "Nível de Qualidade OCR",
    "Editado": "Editado",
    "CCIR": "CCIR",
    "CAR": "CAR",
    "NomeTipoCertidao": "Tipo de Certidão",
    "ArquivoMatricula": "Arquivo Matrícula",
    "Ambiental": "Ambiental",
    "OnusImpactoLiquidez": "Ônus, gravames ou condições que impactem a liquidez e/ou transferência do imóvel",
    "TipoConsulta": "Tipo Consulta",
    "ValorGarantidoAlienacaoFiduciaria": "Valor garantido por alienação fiduciária",
    "CredorAlienacaoFiduciaria": "Credor da alienação fiduciária",
    "ReservaLegalAverbada": "Reserva legal averbada",
    "CliqueAquiParaVerAto": "Clique aqui para ver o ato",
    "UsuarioVerAlerta": "Visualizar Alerta De",
    "VerAlerta": "Visualizar Alerta",
    "UsuarioVerAlertaTitulo": "Configurar Usuários ver Alerta",
    "Sair": "Sair",
    "AlterarSenha": "Alterar Senha",
    "SenhaAtual": "Senha Atual",
    "DataAcessoDe": "Data Acesso De",
    "DataAcessoAte": "Data Acesso Até",
    "Erro": "Erro",
    "Sucesso": "Sucesso",
    "Usuario": "Usuário",
    "DataDe": "Data De",
    "DataAte": "Data Até",
    "Rotina": "Rotina",
    "Chave": "Chave",
    "Detalhes": "Detalhes",
    "Chaves": "Chaves",
    "Campo": "Campo",
    "ValorAnterior": "Valor Anterior",
    "ValorAtualizado": "Valor Atualizado",
    "RegistrosFilhos": "Registros Filhos",
    "CamposModificados": "Campos Modificados",
    "PesquisarCertidoes": "Pesquisar Certidões",
    "PesquisarCertidao": "Pesquisar certidão",
    "NaoExistemDados": "Não existem dados à serem apresentados.",
    "Certidao": "Certidão",
    "Status": "Status",
    "DataEmissao": "Data Emissão",
    "DatadeEmissao": "Data de Emissão",
    "DatadeVencimento": "Data de Vencimento",
    "PrazoValidade": "Prazo Validade",
    "Arquivo": "Arquivo",
    "AtualizarCertidao": "Atualizar Certidão",
    "BaixarSelecionados": "Baixar Selecionados",
    "AtualizarSelecionados": "Atualizar Selecionados",
    "DenominacaoImovel": "Denominação do Imóvel Rural",
    "Municipio": "Município",
    "Situacao": "Situação",
    "CondicaoCadastro": "Condição Cadastro",
    "SituacaoCadastro": "Situação Cadastro",
    "Responsavel": "Responsável",
    "NumeroRIP": "No. RIP",
    "RegimeUtilizacao": "Regime de Utilização",
    "AreaTotalHa": "Área Total(ha)",
    "ValorTotal": "Valor Total",
    "CodigoImovelRural": "Código do Imóvel Rural",
    "Condicao": "Condição",
    "ClassificacaoFundiaria": "Classificação Fundiária",
    "Exercicio": "Exercício",
    "NomeDeclarante": "Nome Declarante",
    "ErroConsultaMatricula": "Erro no processamento do arquivo {0} para a operação {1}. O erro retornado foi {2}",
    "ErroConsultaCertidao": "Erro na consulta das certidões da operação {0}. O erro retornado foi {1}",
    "ErroConsultaCertidaoMatricula": "Erro na consulta das certidões da operação {0} para a matrícula {1}. O erro retornado foi {2}",
    "SucessoConsultaMatricula": "Matrícula {0} processada com sucesso para operação {1}",
    "SucessoConsultaCertidao": "Consulta de Certidões concluída com sucesso para operação {0}",
    "SucessoConsultaCertidaoMatricula": "Consulta de Certidões concluída com sucesso para matrícula {1} da operação {0}",
    "ExpiracaoCertidao": "Expiração da certidão de {0}",
    "ExpiracaoCertidaoTipo": "A certidão <b>{0}</b> expira em <b>{1}</b>",
    "ConsultaCertidao": "Certidões para operação {0}",
    "ConsultaCertidaoMatricula": "Certidões para matrícula {1} da operação {0}",
    "Rotulo": "Rótulo",
    "Historico": "Histórico",
    "IdentificarMudancaProprietario": "Identificar Mudança Proprietário",
    "IdentificarMudancaValorFinanceiro": "Identificar Mudança Valor Financeiro",
    "IdentificarAreaRelacionada": "Identificar Área Relacionada",
    "IdentificarReferenciaAtoAnterior": "Identificar Referência Ato Anterior",
    "IdentificarCredor": "Identificar Credor",
    "ContabilizarNovaHipoteca": "Contabilizar Nova Hipoteca",
    "ContabilizarNovoPenhor": "Contabilizar Novo Penhor",
    "ContabilizarCancelamentoEvento": "Contabilizar Cancelamento Evento",
    "Destacar": "Destacar",
    "UltimaAlteracao": "Última Alteração",
    "IdentificarDadosMatricula": "Identificar dados na matrícula",
    "NovoEvento": "Novo Evento",
    "EventoFGTS": "FGTS",
    "Identificacao": "Identificação",
    "Acoes": "Ações",
    "Notificacoes": "Notificações",
    "NrMatricula": "N° Matrícula",
    "AlterarSituacao": "Alterar Situação",
    "TextoReservado": "Due Diligence Imobiliária é um sistema da E-Fluxus. Todos direitos reservados.",
    "Estoque": "Estoque",
    "ResumoPeriodo": "Resumo por período",
    "ResumoPeriodoVolumetria": "Resumo por período / volumetria",
    "TodasAreas": "Todas Áreas",
    "Periodo": "Período",
    "EstoqueAtual": "Estoque atual",
    "EstoqueAnterior": "Estoque anterior",
    "Entradas": "Entradas",
    "Saidas": "Saídas",
    "Canceladas": "Canceladas",
    "SaidaCanceladas": "Saídas + Canceladas",
    "EstoqueAnteriorEntrada": "Estoque anterior + Entradas",
    "Concluidas": "Concluídas",
    "NaoConcluidas": "Não Concluídas",
    "Efetivadas": "Efetivadas",
    "Corretas": "Corretas",
    "Editadas": "Editadas",
    "Efetivadas_Sucesso": "Efetivadas/Sucesso",
    "EnviarFeedback": "Enviar Feedback",
    "Mensagem": "Mensagem",
    "MotivoContato": "Motivo do Contato",
    "Anexo": "Anexo",
    "Ref.": "Ref.",
    "SugerimosVerificarAMatricula": "Sugerimos verificar a matrícula pois pode haver atos que não identificamos",
    "TranscrissaoMatriculaAnterior": "Transcrição Matrícula Anterior",
    "TranscrissaoMatriculaAnteriorAlerta": "Atenção, verificar dados transportados da matrícula anterior!",
    "TranscrissaoMatriculaAnteriorCalculo": "Devido a transcrição de matrícula anterior no(s) primeiro(s) ato(s), recomendamos revisar o cálculo dos valores das hipotecas",
    "ReferenciaNaoIdentificada": "Referência não identificada",
    "AtoNaoIdentificado": "º ato(não identificado)",
    "ReferenciaEncontradaAbertura": "Referência encontrada na abertura",
    "RegimeMatrimonial": "Regime Matrimonial",
    "HipotecaDe": "Hipoteca de",
    "NumGrau": "º grau",
    "Credor": "Credor",
    "de": "de",
    "ValorVenalDe": "Valor Venal de ",
    "BaseCalculoITBI": "Base de cálculo ITBI ",
    "PesquisarApenasPendentesVencidas": "Certidões ainda não pesquisadas ou certidões vencidas",
    "PesquisarDadosResultadoCertidao": "Utilizar Resultado para Completar Certidões",
    "ConfirmarExclusaoCertidao": "Confirma a exclusão da certidão?",
    "Exportar": "Exportar",
    "HistoricoCertidoes": "Histórico de Certidões",
    "HistoricoMatriculas": "Histórico de Matrículas",
    "NaoExistemRequisicoesPeriodo": "Não existem requisições no período pesquisado",
    "Quantidade": "Quantidade",
    "NomeArquivo": "Nome do Arquivo",
    "Paginas": "Páginas",
    "HistoricoPesquisa": "Histórico de Pesquisa de Certidões",
    "ParametrosPesquisa": "Parâmetros da Pesquisa",
    "ProprietariosParecer": "Proprietários no Parecer",
    "TodosProprietarios": "Todos",
    "UltimoAtoProprietarios": "Último Ato",
    "DescricaoImovel": "Descrição do Imóvel",
    "UltimoAditamentoAto": "(Último aditamento no {0}° Ato)",
    "TransporteMatriculaAnteriorAbertura": "Atenção: Verifique possíveis atos na Abertura da matrícula!",
    "TituloEstabelecimento": "Título Estabelecimento (Fantasia)",
    "NumeroInscricaoEstadual": "N° Inscrição Estadual",
    "CNAEFPrincipal": "CNAE-F Principal",
    "SituacaoCadastral": "Situação Cadastral",
    "CNAEFSecundario": "CNAE-F Secundária",
    "CertidoesMatricula": "Certidões para Matrícula",
    "MeusFeedbacks": "Meus Feedbacks",
    "DemandaResolvida": "Demanda Resolvida",
    "Pendente": "Demanda Pendente",
    "DataCadastro": "Data Cadastro",
    "Resposta": "Resposta",
    "DataResposta": "Data da Resposta",
    "ControleFeedbacks": "Controle de Feedbacks",
    "ResponderFeedback": "Responder Feedback",
    "TempoResposta": "Tempo de Resposta",
    "TempoPendencia": "Tempo de Pendência",
    "PossuiAnexo": "Possui Anexo",
    "FeedbacksUsuario": "Feedbacks por usuário",
    "FeedbacksMotivo": "Feedbacks por motivo",
    "FeedbacksSituacao": "Feedbacks por situação",
    "FeedbacksAnexo": "Feedbacks com anexos",
    "ComAnexo": "Com Anexo",
    "SemAnexo": "Sem Anexo",
    "ResumoFeedbacks": "Resumo Feedbacks",
    "IdentificarVendaCommodity": "Indentificar Venda de Commodity",
    "IdentificarMudancaArea": "Identificar Mudança de Área",
    "BuscaExata": "Busca Exata",
    "NumeroCertidoes": "Quantidade de Certidões",
    "SegundosResultado": "Tempo Processamento (Segundos)",
    "TempoTotalSegundos": "Tempo Total(Segundos)",
    "TempoMedioSegundos": "Tempo Médio(Segundos)",
    "DashboardTemposProcessamento": "Tempos de Processamento",
    "QuantidadeRequisicoes": "Quantidade Requisições",
    "PercentualSucesso": "Percentual de Sucesso",
    "TempoProcessamento": "Tempo de Processamento",
    "Sincronizar": "Sincronizar",
    "ConfirmarSyncEventosImobiliarios": "Confirma a sincronização dos eventos?",
    "CartorioComarcar": "Cartório / Comarca",
    "Cartorio": "Cartório",
    "Comarca": "Comarca",
    "Estado": "Estado",
    "UF": "UF",
    "CalculadoraPenhor": "Calculadora de Penhores",
    "HistoricoPenhores": "Histórico de Penhores",
    "Produto": "Produto",
    "ValorTotalGarantidoPenhor": "Valor (histórico) total do crédito garantido por Penhor(es)",
    "ValorTotalGarantidoPenhorCommodity": "Quantidade total de produto(s) dada em penhor(es)",
    "QuantidadePenhor": "Total de Penhor(es)",
    "ValorCreditoGarantido": "Valor crédito garantido",
    "ValorQtdCreditoGarantido": "Quantidade penhor dado em garantia",
    "TranscrissaoMatriculaAnteriorCalculoPenhor": "Devido a transcrição de matrícula anterior no(s) primeiro(s) ato(s), recomendamos revisar o cálculo dos valores dos Penhores",
    "UtilizacaoFGTS": "Utilização FGTS nos últimos 3 anos",
    "AlertaFGTSUtilizacao": "Confirmar prazo nova utilização FGTS",
    "Condominos": "Condôminos",
    "CPFCNPJ": "CPF / CNPJ",
    "Percentual": "Percentual",
    "CodigoOperacao": "Código Operação / ID",
    "QuantidadeTotalDia": "Quantidade Total Dia",
    "QuantidadeTotal": "Quantidade Total:",
    "OperacaoID": "Operação / ID",
    "Baixar": "Baixar",
    "DataBaixa": "Data da Baixa",
    "DataRespostaPendencia": "Data Pendência",
    "RespostaPendencia": "Resposta Pendência",
    "DemandaPendente": "Demanda Pendente",
    "IdFeedback": "Id Feedback",
    "MotivoContatoDetalhe": "Motivo Detalhe",
    "FeedbacksInformacaoIncorreta": "Feedbacks por informações incorretas",
    "MatriculasProcessadas": "Matrículas Processadas",
    "QuantidadeRequisicoesProcessadas": "Quantidade Requisições Processadas",
    "ConsultasEfetuadasCertidao": "Consultas Efetuadas",
    "ProcessadasObtidas": "Processadas/Obtidas",
    "Processadas": "Processadas",
    "Obtidas": "Obtidas",
    "MatriculaComPrenotacao": "ALERTA EXISTENCIA DE PRENOTAÇÃO",
    "MatriculaComPrenotacaoToolToolTip": "Solicitar/aguardar posicionamento cartório",
    "MensagemAtoValorImovel" : "Verificar atualização do valor do imóvel",
    "valorAvaliacaoImovel" : "VALOR AVALIAÇÃO OU DA ÚLTIMA TRANSAÇÃO",
    "AnalisadasGraf" : "Analisadas (não inclui atualizadas)",
    "ReferNaoIdent": "Referência encontrada na Abertura ou em Ato Não Identificado",
    "SegundaVia": "2ª Via",
    "DistritoMunicipio":"Distrito / Municipio",
    "NIRE": "NIRE - Nº de Identificação do Registro de Empresas",
    "DataConstituicao": "Data Constituíção",
    "PrazoDuracao": "Prazo de Duracao",
    "TipoJuridico": "Tipo Jurídico"
};
